<template>
  <div class="article" style="">
        <el-row class="filter_row">
          <div class="fliter_row_back_mask">
          </div>
          <el-col :md="15" v-if="typeList.length<=0">
            <el-skeleton style="width:800px" :rows="4" animated />
          </el-col>
          <el-col :md="15" v-if="typeList.length<=0">
            <el-skeleton style="width:900px;margin-top: 10px;" :rows="4" animated />
          </el-col>
          <el-col :md="15" :sm="24" :xs="24" v-for="typeItem in typeList">
            <el-row>
              <el-col :md="3" :xs="3" :sm="3" style="text-align: center;">
                <ButtonTypeNameUi style="height: 2.015625rem;line-height: 2.015625rem; width: 100%;
                border-bottom: 2px solid #F3F4F6;margin-right: 5px;
                ">
                  {{typeItem.typeName}}
                </ButtonTypeNameUi>
              </el-col>
              <el-col :md="{span:21}" :xs="{span:21}">
                <ButtonTypeCheckUi
                  style="margin-bottom: 10px;
                  font-weight:bold;
                  "
                  v-for="labelItem in typeItem.labelList"
                  :value="labelItem.id"
                  @checked="value=>query.labelList.push(value)"
                  @unCheck="removeQueryLabelList"
                  @change="orderSearch"
                  :content="labelItem.labelName"
                >
                {{labelItem.labelName}}
                </ButtonTypeCheckUi>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row class="content_row" :gutter="15">
          <el-col :md="20" :xs="24" :sm="24" style="margin-bottom: 15px;">
            <el-select v-model="query.orderType" placeholder="排序规则" @change="orderSearch">
              <el-option
              label="时间"
              value="date"
              >
              </el-option>
              <el-option
              label="点赞"
              value="praise"
              >
              </el-option>
              <el-option
              label="默认(混合排序)"
              value="default"
              :checked="true"
              >
              </el-option>
            </el-select>
            <Sort @change="sortSearch"></Sort>
          </el-col>
          <el-col :md="{span:4}" :xs="{span:20}" :sm="24">
              <InputSearchUi label="搜索文章"
              @searchCond="searchCond"
              ></InputSearchUi>
          </el-col>
        <el-col
        v-if="!blogListHasData"
        v-for="item in [1,2,3,4]"
        :md="{span:6}"
        :xs="{span:24}"
        >
            <div v-for="item in [1,2]">
              <el-skeleton style="width: 100%" animated>
                  <template slot="template">
                    <el-skeleton-item variant="text" style="width: 100%; height: 240px;" />
                    <div style="padding: 14px;">
                      <el-skeleton-item variant="p" style="width: 50%" />
                      <div
                        style="display: flex; align-items: center; justify-items: space-between;"
                      >
                        <el-skeleton-item variant="text" style="margin-right: 16px;" />
                        <el-skeleton-item variant="text" style="width: 30%;" />
                      </div>
                    </div>
                  </template>
              </el-skeleton>
            </div>
          </el-col>
          <el-col
          v-for="(blogListItem,index) in blogList"
          :key="index"
          :md="{span:6}"
          :xs="{span:24}"
          v-infinite-scroll="load"
          infinite-scroll-disabled="disabled"
          infinite-scroll-immediate="false"
          :infinite-scroll-distance="500"
          >
            <div v-for="blogItem in blogListItem.blogListItem">
              <transition name="el-fade-in" :appear="true">
                <div @click="link_to('/details',blogItem.id,$event)">
                   <blogCard style="margin-bottom: 15px;" :blog="blogItem" ></blogCard>
                </div>
              </transition>
            </div>
          </el-col>
          <div style="height: 100px;float:left;width: 100%;">
              <h4 v-if="noMore && !loading"style="color:#BABE99;text-align: center;">没有更多了哦！</h4>
              <div v-loading="loading" style="margin-top: 20px;">
              </div>
            </div>
          <div
          style="height: 450px;"
          v-if="loading"
          >
          </div>
        </el-row>

  </div>
</template>
<script>
import { getArticelApi,getAllTypeApi } from '@/api/article'
import global from '@/api/global_variable.js'
import blogCard from '@/components/blogCard.vue'
import ButtonTypeCheckUi from '@/components/ui/ButtonTypeCheckUi'
import InputSearchUi from '@/components/ui/InputSearchUi'
import ButtonTypeNameUi from '@/components/ui/ButtonTypeNameUi'
export default {
  name:'blog',
  components: {
    blogCard,
    ButtonTypeCheckUi,
    InputSearchUi,
    ButtonTypeNameUi
  },
  data () {

    return {
      externalLink: {
        markdown_css: function () {
          // 这是你的markdown css文件路径
          return '/mavon-editor/markdown/github-markdown.min.css'
        },
        hljs_js: function () {
          // 这是你的hljs文件路径
          return '/mavon-editor/highlightjs/highlight.min.js'
        },
        hljs_css: function (css) {
          // 这是你的代码高亮配色文件路径
          return '/mavon-editor/highlightjs/styles/' + css + '.min.css'
        },
        hljs_lang: function (lang) {
          // 这是你的代码高亮语言解析路径
          return '/mavon-editor/highlightjs/languages/' + lang + '.min.js'
        },
        katex_css: function () {
          // 这是你的katex配色方案路径路径
          return '/mavon-editor/katex/katex.min.css'
        },
        katex_js: function () {
          // 这是你的katex.js路径
          return '/mavon-editor/katex/katex.min.js'
        }
      },
      defaultAvatarURL:'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      loading:true,
      query: {
        pageNum: 1,
        pageSize: 16,
        condition:undefined,
        labelList:[],
        orderType:null
      },
      preImgUrlList: [],
      queryLabelList:[],
      articleList: [],
      typeList:[],
      blogList:[
        {
          blogListItem:[],
        },{
          blogListItem:[],
        },{
          blogListItem:[],
        },{
          blogListItem:[],
        }
      ],
      blogListAll:[],
      blogListHasData:false,
      imgURL:global.imgURL,
      totalNumber:0,
      totalPage:0,
      currentPage:1,
      loadingObj:{}
    }
  },
  computed: {
    prop () {
      let data = {
        subfield: false, // 单双栏模式
        defaultOpen: 'preview', // edit： 默认展示编辑区域 ， preview： 默认展示预览区域
        editable: false,
        toolbarsFlag: false,
        scrollStyle: true,
        codeStyle: 'color-brewer'
      }
      return data
    }
  },
  created () {
    this.getBlogList()
    this.getAllType()
  },
  computed:{
    noMore() {
          return this.totalNumber < this.query.pageSize || this.totalPage===this.currentPage;
    },
    disabled() {
          return this.loading || this.noMore;
    },
  },
  methods: {
    removeQueryLabelList(value){
      let index=this.query.labelList.findIndex(item=>item==value)
      this.query.labelList.splice(index,1)
    },
    link_to(path,blogId,event){
        this.$router.push({path: path, query: {blogId:blogId}})
    },
    previewCurrentImg(preImgUrl){
      this.preImgUrlList=[]
      this.preImgUrlList.push(preImgUrl)
    },
    restBlogList(){
      this.blogList=[
        {
          blogListItem:[],
        },{
          blogListItem:[],
        },{
          blogListItem:[],
        },{
          blogListItem:[],
        }
      ]
      this.blogListHasData=false
    },
    restQuery(){
      this.query.pageNum=1
    },
    load() {
      this.getBlogList();
    },
    sortSearch(sort){
      this.query.sort=sort
      this.orderSearch()
    },
    orderSearch(){
      this.restQuery()
      this.restBlogList()
      this.getBlogList()
    },
    searchCond(condition){
      this.query.condition=condition
      this.restQuery()
      this.restBlogList()
      this.getBlogList()
    },
    labelChange(){
      this.restQuery()
      this.restBlogList()
      this.getBlogList()
    },
    async getAllType(){
      const res= await getAllTypeApi()
      this.typeList=res.data.data
    },
    async getBlogList () {
      this.loading=true
      await getArticelApi(this.query).then((res)=>{
        if(res.data.success){
          const blogListAll=res.data.data.list;
          this.totalNumber=res.data.data.list.length;
          let  i=0;
          for( i=0;i<blogListAll.length;++i){
            this.blogList[i%4].blogListItem.push(blogListAll[i]);
          }
          this.blogListHasData=true
          // this.blogList = res.data.data.list
          this.query.pageNum++;
          this.totalPage=res.data.data.pages
          this.currentPage=res.data.data.pageNum
        }
        this.loading=false;
      })


    }
  }
}
</script>
<style lang="less" scoped>
  .el-col{
    border: 1px solid transparent;
  }
  // *{
  //   // overflow: auto;
  //   vertical-align:middle;
  // }
  a{
    text-decoration: none;
  }
  button{
     // cursor: pointer
  }
  .article{
    flex: 1;
    // width: 100%;
    // overflow: auto;
  }
  .filter_row{
    // font-size: 13px;
    width: 100%;
    border:1px solid transparent;
    background:url('https://qianyafan.oss-cn-beijing.aliyuncs.com/home_blog02.jpg') no-repeat;
    background-position: 0px -300px;
    background-attachment: fixed;
    background-size: cover;
    padding-top: 50px;
  }
  .fliter_row_back_mask{
    width:100%;
    height:100%;
    position: absolute;
    background-color: rgba(0,0,0,.3);
    margin-top:-50px;
  }
  .filter_name{
    color:#F3F4F6;
    font-size: 15px;
    display:inline-block;
    // width: 100px;
    height:26px;
    line-height:26px;
    margin-right: 20px;
    float: left;
  }
  .filter_button{
    // background-color: rgba(0,0,0,0);
    // color:#F3F4F6;
    // padding:0px;
    // border:1px solid #F3F4F6;
    // // border-radius: 30px;
    // padding: 5px;
    // transition-property: all;
    // transition-duration: 0.5s;
    // margin-right: 10px;
    // cursor: pointer;
  }
  .el-checkbox-group{
    // width: 80%;
    float:left;
  }
/deep/.el-checkbox-button:first-child .el-checkbox-button__inner{
  background-color: rgba(0,0,0,0);
  color:#F3F4F6;
  padding:0px;
  border:1px solid #F3F4F6;
  // border-radius: 30px;
  padding: 5px;
  transition-property: all;
  transition-duration: 0.2s;
  margin-right: 10px;
  border-radius: 0px;
  cursor: pointer;
}
/deep/.el-checkbox-button:first-child .el-checkbox-button__inner:hover{
  background-color: #F3F4F6;
  color:#303133;
}
/deep/.el-checkbox-button:last-child .el-checkbox-button__inner:hover{
  background-color: #F3F4F6;
  color:#303133;
}
  /deep/.el-checkbox-button:last-child
  .el-checkbox-button__inner{
    background-color: rgba(0,0,0,0);
    color:#F3F4F6;
    padding:0px;
    border:1px solid #F3F4F6;
    // border-radius: 30px;
    padding: 5px;
    transition-property: all;
    transition-duration: 0.2s;
    margin-right: 10px;
    border-radius: 0px;
    cursor: pointer;
  }
 /deep/ .el-checkbox-button__inner{
    background-color: rgba(0,0,0,0);
    color:#F3F4F6;
    padding:0px;
    border:1px solid #F3F4F6;
    // border-radius: 30px;
    padding: 5px;
    transition-property: all;
    transition-duration: 0.2s;
    margin-right: 10px;
    border-radius: 0px;
    cursor: pointer;
    margin-bottom: 10px;
    // font-size: 13px;
  }
 /deep/ .el-checkbox-button__inner:hover{
    background-color: #F3F4F6;
    color:#303133;
  }
  .filter_button_selected{
    background-color: #F3F4F6;
    color:#303133;
  }
  .content_row{
    float: left;
    width: 100%;
    background-color: #E8E8E8;
    padding-top: 20px;
    padding-left: 5px;
    // border-radius: 20px;
  }
  .content_card{
    flex-wrap: wrap;
    justify-content: start;
    background-color: #F3F4F6;
    width:100%;
    margin-bottom:15px;
    border:none;
    padding:0px;
    // border-radius: 5px;
    transition-property: all;
    transition-duration: 0.5s;


  }
  .content_card:hover{
    box-shadow: 2px 2px 10px #F7F7B8;
  }
 /deep/ .el-card__body {
    padding: 0;
    border-radius:0px
  }
  .blog_image{
    width: 100%;
  }
  .blog_heading{
    padding-left: 10px;
    margin:0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #B4B5B7;
    margin-bottom: 10px;
    // width: 284px;
    width: 100%;
  }
  .blog_synopsis{
    font-size: 13px;
    padding-left: 10px;
    margin-bottom: 10px;
    padding-right: 10px;
    color:#969799;
    // height: 100px;
  }
  .blog_label{
    font-size: 13px;
    padding-left: 10px;
    margin-bottom: 10px;
    padding-right: 10px;
  }
  .blog_label_tag{
    border:none;
    margin-right: 10px;
    color:#F3F4F6;
  }
  .blog_synopsis_name{
    // width: ;
  }
  .blog_synopsis_content{
    word-break: break-all;
  }
  .blog_create_time{
    padding-left: 10px;
    margin-bottom: 10px;
    font-size: 13px;
    color:#969799;
  }
  .blog_operate{
    padding-left: 10px;
    margin-bottom: 10px;
    line-height: 100%;
  }
  .blog_operate_botton{
    font-size: 13px;
    border-radius: 100%;
    border: none;
    color:#969799;
    padding: 0px;
    margin-top:8px;
  }
  .blog_operate_user{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .blog_operate_user a{
    text-decoration: none;
    color:#303133;
    transition-property: all;
    transition-duration: 0.5s;
  }
  .blog_operate_user a:hover{
   color:#A7A8AA;
  }
  .blog_operate_username{
    font-size: 10px;
    margin-left: 5px;
    overflow: hidden;
  }
  .filter_select_input{
    width: 20%;
    font-size: 13px;
    background:rgba(255,255,255,0);
    border-radius: 0px;
    border:1px solid #F3F4F6;
    // padding: 6px;
    height: 30px;
    box-sizing: border-box;
    outline: none;
    vertical-align:middle;
    padding-left: 10px;
    padding-right: 10px;
    border-right: none;
    color:#F3F4F6;
    margin-bottom: 10px;
  }
  .filter_select_button{
    margin-bottom: 10px;
    color:#F3F4F6;
    border-radius: 0px;
    width: 50px;
    height: 30px;
    cursor: pointer;
    // background-color:transparent;
    border:1px solid #F3F4F6;
    background:rgba(255,255,255,0.0);
    border-left: 0px;
    vertical-align:middle;
    transition-property: all;
    transition-duration: 0.5s;
  }
  .filter_select_button:hover{
    background-color: #F3F4F6;
    color:#303133;
  }
</style>
