<template>

  <div style="
  text-align: center;color: #FFFFFF;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  ">
    <slot></slot>
  </div>
</template>

<script>
  export default{
    name:'ButtonTypeNameUi'
  }
</script>

<style scoped>

</style>
