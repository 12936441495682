<template>
      <button :class="!status?'btn':'checked'"
      @click="check"
      :data-attr="content"
      >
          <slot></slot>
      </button>
</template>

<script>
  export default{
    name:'ButtonTypeCheckUi',
    props:['content','value'],
    data() {
      return {
        status:false,
      }
    },
    methods: {
      check() {
        this.status=!this.status
        if(this.status){
          this.$emit('checked',this.value)
        }else{
          this.$emit('unCheck',this.value)
        }
        this.$emit('change')
      }
    },
  }
</script>

<style scoped>
.btn {
  font-size: 15px;
  background: transparent;
  border: none;
  padding: 0.5em 1.5em;
  color: #F3F4F6;
  text-transform: uppercase;
  position: relative;
  /* transition: .5s ease; */
  cursor: pointer;
}
.checked{
  font-size: 15px;
  background: transparent;
  border: none;
  padding: 0.5em 1.5em;
  text-transform: uppercase;
  position: relative;
  transition: 0.2s ease;
  cursor: pointer;
  color: #181818;
  background-color: #F3F4F6;
}
.btn::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 2px;
  width: 0;
  background-color: #F3F4F6;
  transition: .5s ease;
}

.btn:hover {
  color: #181818;
  transition-delay: .5s;
  /* background-color: #F3F4F6; */
}

.btn:hover::before {
  width: 100%;
}

.btn::after {
  content:attr(data-attr);
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0px;
  width: 100%;
  color: #181818;
  background-color: #F3F4F6;
  transition: .4s ease;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  line-height: 2.015625rem;
  font-size: 15px;
}

.btn:hover::after {
  height: 100%;
  transition-delay: 0.4s;
  overflow: hidden;
}
</style>
